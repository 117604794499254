export function useMultiLingual() {
  const config = useRuntimeConfig();
  const { fallbackLocale, isMultilingual } = config.public;

  /**
   * Check if the site is multilingual. Use the NUXT_PUBLIC_IS_MULTILINGUAL environment variable to set this.
   */
  function isMultilingualSite(): boolean {
    return isMultilingual as unknown as boolean;
  }

  /**
   * Get the fallback locale. Use the NUXT_PUBLIC_FALLBACK_LOCALE environment variable to set this.
   * */
  function useFallbackLocale(): string {
    return fallbackLocale;
  }

  /**
   * Redirect to the fallback locale.
   */
  function redirectToFallbackLocale(redirectCode = 302) {
    if (!fallbackLocale) {
      const loggingService = useLoggingService();
      loggingService.error('No fallback locale set in config.');
      return;
    }

    navigateTo(`/${useFallbackLocale()}`, {
      redirectCode
    });
  }

  /**
   * Check if the current path is the root path and if so, redirect to the fallback locale.
   * Only runs if the site is multilingual and a fallback locale is set.
   */
  function handleMultilingualRedirect(path: string) {
    if (!isMultilingualSite()) {
      return;
    }

    if (path === '/') {
      redirectToFallbackLocale();
    }
  }

  /**
   * Extracts the locale code from a URL path that can be either two-letter or four-letter.
   * @returns {string | null} The extracted locale code, or null if not found.
   */
  function extractLocaleFromPath(path: string): string | null {
    const localeRegExp = /^\/([a-z]{2}(?:-[a-z]{2})?)/;
    const match = path.match(localeRegExp);
    return match ? match[1] : null;
  }

  /**
   * Removes slashes from a string. Used to remove slashes from paths.
   * */
  function removeSlashes(inputString: string) {
    return inputString.replace(/\//g, '');
  }

  return {
    isMultilingualSite,
    redirectToFallbackLocale,
    handleMultilingualRedirect,
    useFallbackLocale,
    extractLocaleFromPath,
    removeSlashes
  };
}
