import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/usr/src/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import humblescroll_VWHyBoSbvn from "/usr/src/app/node_modules/humblescroll-nuxt/dist/runtime/humblescroll.mjs";
import formkitPlugin_pZqjah0RUG from "/usr/src/app/.nuxt/formkitPlugin.mjs";
import plugin_tLmAP220nC from "/usr/src/app/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import _1_init_TMCQR3gW8D from "/usr/src/app/src/plugins/1.init.ts";
import _2_i18n_aU5g4N7sv6 from "/usr/src/app/src/plugins/2.i18n.ts";
import _3_vue_gtm_87sonxomdG from "/usr/src/app/src/plugins/3.vue-gtm.ts";
import _4_application_insights_3Xs9hyPQOA from "/usr/src/app/src/plugins/4.application-insights.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  humblescroll_VWHyBoSbvn,
  formkitPlugin_pZqjah0RUG,
  plugin_tLmAP220nC,
  _1_init_TMCQR3gW8D,
  _2_i18n_aU5g4N7sv6,
  _3_vue_gtm_87sonxomdG,
  _4_application_insights_3Xs9hyPQOA
]