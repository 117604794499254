<script setup lang="ts">
type ButtonSize = 'label' | 'small' | 'large' | 'add-to-basket' | 'quantity-selector';
type ButtonVariant = 'primary' | 'secondary' | 'ghost' | 'quantity-selector';

type Props = {
  to?: string;
  target?: string;
  modifiers?: string[];
  size?: ButtonSize;
  variant?: ButtonVariant;
  arrowIcon?: boolean;
  title?: string;
};

const props = withDefaults(defineProps<Props>(), {
  to: undefined,
  target: undefined,
  modifiers: undefined,
  arrowIcon: true,
  size: 'large',
  variant: 'primary',
  title: undefined
});

const buttonClasses = computed(() => [
  props.size && `size__${props.size}`,
  props.variant && `variant__${props.variant}`
]);
</script>

<template>
  <BaseLink
    v-if="props.to !== undefined"
    :class="['button', buttonClasses]"
    :to="props.to"
    :target="props.target"
    :title="title"
  >
    <slot>{{ title }}</slot>
    <BaseIcon
      v-if="arrowIcon"
      class="relative -top-px ml-2 text-icon transition-transform duration-200 group-hover/item:translate-x-2"
      name="chevron-right"
    />
  </BaseLink>

  <button
    v-else
    type="button"
    :class="['button', buttonClasses]"
    :title="title"
  >
    <slot>{{ title }}</slot>
    <BaseIcon
      v-if="arrowIcon"
      class="relative -top-px ml-2 text-icon transition-transform duration-200 group-hover/item:translate-x-2"
      name="chevron-right"
    />
  </button>
</template>

<style lang="postcss" scoped>
.button {
  --background: var(--color-primary);
  --color: var(--color-white);
  --border-color: var(--color-primary);

  --hover-background: var(--color-tertiary);
  --hover-color: var(--color);
  --hover-border-color: var(--hover-background);

  --focus-background: var(--hover-background);
  --focus-color: var(--color);
  --focus-border-color: var(--focus-background);

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: 500;
  user-select: none;
  color: var(--color);
  border: 1px solid var(--border-color);
  background-color: var(--background);
  transition: 200ms ease-out;
  position: relative;
  overflow: hidden;

  &:hover,
  &.hover {
    color: var(--hover-color);
    background-color: var(--hover-background);
    border-color: var(--hover-border-color);
  }

  &:focus,
  &.focus,
  &.pressed {
    color: var(--focus-color);
    background-color: var(--focus-background);
    border-color: var(--focus-border-color);
  }

  &.variant {
    &__secondary {
      --background: var(--color-secondary);
      --border-color: var(--background);

      --hover-background: var(--color-primary);
      --hover-border-color: var(--hover-background);

      --focus-background: var(--hover-background);
      --focus-border-color: var(--focus-background);
    }

    &__ghost {
      --background: transparent;
      --border-color: var(--color-white);

      --hover-background: var(--color-white);
      --hover-border-color: var(--hover-background);
      --hover-color: var(--color-text);

      --focus-background: var(--hover-background);
      --focus-border-color: var(--focus-background);
      --focus-color: var(--hover-color);
    }

    &__quantity-selector {
      --background: transparent;
      --border-color: var(--color-gray-90);
      --color: var(--color-black);
      --hover-background: var(--color-secondary);
      --hover-border-color: var(--color-primary);
      --hover-color: var(--color-white);

      --focus-background: var(--background);
      --focus-border-color: var(--border-color);
      --focus-color: var(--color);

      border-width: 2px;

      &:hover {
        background: var(--background);
        border-color: var(--border-color);
        color: var(--color);
      }
    }
  }

  &.size {
    &__large {
      padding: 1rem 2rem;
    }

    &__small {
      padding: 10px 1rem;
    }

    &__label {
      padding: 0.5rem 12px;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
    }

    &__quantity-selector {
      padding: 0.75rem;
      height: 100%;
    }

    &__add-to-basket {
      padding: 0rem 0.75rem;
    }
  }

  &:disabled,
  &.disabled,
  &.router-link-exact-active {
    opacity: 0.65;
    pointer-events: none;
  }
}
</style>
