import type { NavigationItem } from 'types';

export default function () {
  const getNavigation = (url: string) => {
    return UseBaseApi<NavigationItem[]>('/api/navigation', {
      params: {
        url
      },
      keepalive: true
    });
  };

  return {
    getNavigation
  };
}
