import type { SiteSettings } from 'types';

export default function () {
  const getSettings = (url: string) => {
    return UseBaseApi<SiteSettings>('api/settings/', {
      params: {
        url
      },
      keepalive: true
    });
  };

  return {
    getSettings
  };
}
