<script setup lang="ts">
import type { Error } from 'types';

const props = defineProps<{
  error: Error;
}>();

const root = useRoot();
const { isMultilingualSite } = useMultiLingual();

const frontpageUrl = computed(() => {
  if (isMultilingualSite()) {
    return '/' + root.settings.metadata.culture;
  }

  return '/';
});
const handleError = () => clearError({ redirect: frontpageUrl.value });

// Set SEO tags
useHead({
  title: props.error?.statusCode.toString()
});
</script>

<template>
  <div>
    <NuxtLayout>
      <div class="container">
        <div class="align-center flex flex-col items-center gap-8 py-28 text-center">
          <div>
            <h1 class="mb-4 text-[60px] lg:text-[96px]">
              {{ error?.statusCode }}
            </h1>
            <h2 class="mb-16">{{ $t('ErrorMessages.Generic') }}</h2>
          </div>
          <BaseButton @click="handleError">
            {{ $t('ErrorMessages.GoToFrontpage') }}
          </BaseButton>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
