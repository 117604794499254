import { useNavigation } from 'stores/navigation';

export default defineNuxtRouteMiddleware((to, from) => {
  const { extractLocaleFromPath, isMultilingualSite, removeSlashes } = useMultiLingual();
  const navigation = useNavigation();
  const root = useRoot();

  navigation.hideMenu();

  if (to.query.preview === 'true' && import.meta.server) {
    const event = useRequestEvent();
    event?.node.res.setHeader('X-Robots-Tag', 'noindex, nofollow');
  }

  if (isMultilingualSite()) {
    const toLocale = extractLocaleFromPath(to.path);
    const fromLocale = extractLocaleFromPath(from.path);

    navigation.isFrontPage = removeSlashes(to.path) === toLocale;

    if (toLocale !== fromLocale && toLocale) {
      root.reinitializeSettings(toLocale);
    }
  } else {
    navigation.isFrontPage = to.path === '/';
  }
});
