import { createI18n } from 'vue-i18n';
import type { Translation, TranslationItem, TranslationObject } from 'types';
import type { App } from 'vue';

export default defineNuxtPlugin(async ({ vueApp }: { vueApp: App<Element> }) => {
  const { settings } = useRoot();
  const { useFallbackLocale } = useMultiLingual();
  const loggingService = useLoggingService();

  try {
    const { data: translations } = await useAsyncData('translations', () => getTranslations());

    const formattedTranslations = translations.value?.reduce((obj: TranslationObject, item: TranslationItem) => {
      if (item.translations) {
        item.translations.forEach((translation: Translation) => {
          if (translation.value) {
            obj[translation.culture] = obj[translation.culture] || {};
            obj[translation.culture][item.key] = translation.value;
          }
        });
      }
      return obj;
    }, {});

    const defaultLocale =
      settings?.metadata?.culture === undefined || settings?.metadata?.culture === '/'
        ? settings.metadata.culture
        : useFallbackLocale();

    const i18n = createI18n({
      legacy: false,
      globalInjection: true,
      locale: defaultLocale,
      fallbackLocale: useFallbackLocale(),
      messages: formattedTranslations ?? {}
    });

    vueApp.use(i18n);
  } catch (error) {
    loggingService.error('error setting translations: ', error);
    const i18n = createI18n({
      legacy: false,
      globalInjection: true,
      locale: useFallbackLocale(),
      fallbackLocale: useFallbackLocale(),
      messages: {
        da: {}
      }
    });

    vueApp.use(i18n);
  }
});
