import type { CustomBreakpoints } from 'types/index';

export function customBreakpoints(): CustomBreakpoints {
  return {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
    xxxl: 1920
  };
}

export function frontendBaseWithLocale(locale: string): string {
  const { useFallbackLocale, isMultilingualSite } = useMultiLingual();

  if (!isMultilingualSite()) {
    return useFrontendBase('');
  }

  const selectedLocale = locale || useFallbackLocale();
  return useFrontendBase(`/${selectedLocale}`);
}

export const handleRelativeUrl = (url: string | undefined): string => {
  const config = useRuntimeConfig();
  const { frontendBase } = config.public;

  if (!url) {
    return '';
  }

  if (url.startsWith(frontendBase)) {
    // Internal URL in development environment
    try {
      const parsedUrl = new URL(url);
      return `${parsedUrl.pathname}${parsedUrl.search}${parsedUrl.hash}`;
    } catch (_) {
      // Invalid URL, fallback to original value
      return url;
    }
  }

  // External or absolute URL, return as-is
  return url;
};

/**
 * Checks if an object is empty.
 */
export const objectIsEmpty = (obj: unknown): boolean => {
  if (!obj) {
    return true;
  }

  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export function useCdnBase(image: string, isFile = false, bucket = 'cms') {
  const config = useRuntimeConfig();

  if (!isFile) {
    return `${config.public.imageCdnBase}/${bucket}${image}`;
  }

  return `${config.public.imageCdnBase}/api/storage?filename=/${bucket}${image}`;
}

export function useApiBase(url: string) {
  const config = useRuntimeConfig();
  const { apiBase } = config.public;

  return apiBase + url;
}

export function useFrontendBase(url: string) {
  const config = useRuntimeConfig();
  const { frontendBase } = config.public;

  return frontendBase + url;
}

/**
 * Generates a UUID v4.
 */
export function generateUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * A simple logging service that only logs to console in development or staging environments.
 * @example
 * const loggingService = useLoggingService();
 *
 * loggingService.log('Hello world!');
 * loggingService.warn('Hello world!');
 * loggingService.error('Hello world!');
 */
export function useLoggingService() {
  type LogLevel = 'log' | 'warn' | 'error';

  const {
    public: { environment }
  } = useRuntimeConfig();

  const appInsights = useApplicationInsights();

  function createLogger(level: LogLevel) {
    return function (...args: unknown[]) {
      if (environment !== 'production') {
        // eslint-disable-next-line no-console
        (console[level] as (message?: unknown, ...optionalParams: unknown[]) => void)(...args);
      }

      if (environment !== 'development') {
        appInsights?.trackTrace({ message: `${level}: ${String(args)}` });
      }
    };
  }

  return {
    log: createLogger('log'),
    warn: createLogger('warn'),
    error: createLogger('error')
  };
}

/**
 * A simple service for getting the DOM window and document objects.
 *
 * @example const DOM = useDOM();
 * DOM.window; DOM.document;
 */
export function useDOM() {
  if (import.meta.server) {
    return null;
  }

  return {
    // eslint-disable-next-line no-restricted-globals
    window,
    // eslint-disable-next-line no-restricted-globals
    document
  };
}

/**
 * Retrieves the pixel value of a CSS variable and returns it as a number.
 *
 * @param {string} variable - The name of the CSS variable.
 * @returns {number} The pixel value of the CSS variable as a number, or 0 if the variable doesn't exist or isn't a valid number.
 *
 * @example
 * // CSS: --main-size: 1rem;
 * const headerHeight = extractNumberFromCSSVariable('--header-height');
 * console.log(headerHeight); // Outputs the number of pixels the header height is set to.
 */
export function extractNumberFromCSSVariable(variable: string): number {
  const variableRef = useCssVar(variable);
  let value = variableRef.value;

  if (value?.endsWith('rem')) {
    value = parseFloat(value) * 16 + 'px';
  }

  const number = Number(value?.replace('px', '') || 0);

  return number;
}

/**
 * Truncates a string to a specified length and appends an ellipsis if it exceeds that length.
 *
 * @param {string} text - The string to be truncated.
 * @param {number} maxLength - The maximum length of the string.
 * @returns {string} The truncated string, with an ellipsis appended if it was truncated.
 */
export function truncateTextWithEllipsis(text: string, maxLength: number): string {
  if (text.length > maxLength) {
    return String(text).substring(0, maxLength) + '...';
  }

  return text;
}
