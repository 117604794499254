import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { applicationInsightsConnectionString }
  } = useRuntimeConfig();
  const loggingService = useLoggingService();

  if (!applicationInsightsConnectionString) {
    loggingService.warn('Application Insights connection string is not set. Skipping initialization.');
    return;
  }

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: applicationInsightsConnectionString
    }
  });

  appInsights.loadAppInsights();

  nuxtApp.provide('appInsights', appInsights);
});
