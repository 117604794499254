import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin(({ vueApp }) => {
  const {
    public: { gtmId, environment }
  } = useRuntimeConfig();
  const loggingService = useLoggingService();
  const isProduction = environment === 'production';

  if (!gtmId) {
    loggingService.warn('No GTM ID found. Skipping GTM setup.');
    return;
  }

  const gtm = createGtm({
    id: gtmId,
    defer: true,
    compatibility: false,
    enabled: true,
    debug: !isProduction,
    loadScript: true,
    vueRouter: useRouter(),
    trackOnNextTick: false
  });

  vueApp.use(gtm);
});
