export default defineNuxtPlugin(async () => {
  if (!import.meta.server) {
    return;
  }

  const { path } = useRoute();
  const { deviceInit } = useLayout();
  const { initNavigation } = useNavigation();
  const { initSettings } = useRoot();
  const { handleMultilingualRedirect, extractLocaleFromPath, useFallbackLocale } = useMultiLingual();

  const locale = extractLocaleFromPath(path) ?? useFallbackLocale();

  try {
    await Promise.allSettled([deviceInit(), initSettings(locale), initNavigation(locale)]);

    handleMultilingualRedirect(path);
  } catch (error) {
    throw createError({
      statusCode: 500,
      message: 'Error initializing site'
    });
  }
});
