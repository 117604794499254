import type { Metadata, NavigationItem } from 'types';

export const useNavigation = defineStore('navigation', () => {
  const menuVisible = shallowRef<boolean>(false);
  const activeHeaderIcon = shallowRef<string>('');
  const isFrontPage = shallowRef<boolean>(false);
  const searchVisible = shallowRef<boolean>(false);
  const navigationItems = shallowRef<NavigationItem[]>([]);
  const activeCulture = shallowRef<string>('');
  const activeContentMetaData = shallowRef<Metadata>();
  const megaMenuShownIndex = shallowRef<number>(-1);
  const megaMenuActiveSubLevel = shallowRef<number>(0);
  const megaMenuActiveParentName = shallowRef<string>('root');
  const megaMenuActiveIndexes = ref<number[]>([]);
  const pageHasHero = shallowRef<boolean>(true);

  const { useFallbackLocale } = useMultiLingual();

  const noScrollBodyClass = computed(() => {
    return menuVisible.value ? 'overflow-hidden' : '';
  });

  function toggleMenuVisibility() {
    menuVisible.value = !menuVisible.value;
  }

  function toggleHeroStatus(value: boolean) {
    pageHasHero.value = value;
  }

  function toggleSearchVisibility() {
    searchVisible.value = !searchVisible.value;
  }

  function hideMenu() {
    menuVisible.value = false;
    megaMenuActiveIndexes.value = [];
    megaMenuShownIndex.value = -1;
  }

  function setActiveHeaderIcon(item: string) {
    activeHeaderIcon.value = activeHeaderIcon.value === item ? '' : item;
  }

  async function initNavigation(locale = useFallbackLocale()) {
    const { getNavigation } = useNavigationApi();
    const { data } = await useAsyncData('navigation', () => getNavigation(frontendBaseWithLocale(locale)), {
      deep: false
    });

    if (data.value) {
      navigationItems.value = data.value;
    }

    activeCulture.value = locale;
  }

  function updateActiveSubLevel(subLevel: number, parentName: string = megaMenuActiveParentName.value) {
    megaMenuActiveSubLevel.value = subLevel;
    megaMenuActiveParentName.value = parentName;
  }

  function updateActiveContentMetaData(newMetaData: Metadata) {
    activeContentMetaData.value = newMetaData;
  }

  return {
    menuVisible,
    activeHeaderIcon,
    isFrontPage,
    toggleHeroStatus,
    pageHasHero,
    toggleMenuVisibility,
    hideMenu,
    searchVisible,
    toggleSearchVisibility,
    setActiveHeaderIcon,
    navigationItems,
    initNavigation,
    megaMenuShownIndex,
    megaMenuActiveIndexes,
    megaMenuActiveSubLevel,
    megaMenuActiveParentName,
    updateActiveSubLevel,
    noScrollBodyClass,
    activeCulture,
    activeContentMetaData,
    updateActiveContentMetaData
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNavigation, import.meta.hot));
}
