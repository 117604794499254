<script setup lang="ts">
type Props = {
  to: string;
  icon?: string;
  iconClass?: string;
  hasUnderline?: boolean;
  thickUnderline?: boolean;
  isActive?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  to: '',
  icon: undefined,
  iconClass: undefined,
  hasUnderline: false,
  thickUnderline: false,
  isActive: false
});
</script>

<template>
  <NuxtLink
    :to="handleRelativeUrl(props.to)"
    class="group relative flex w-fit items-center"
    :class="[
      { 'has-underline': props.hasUnderline },
      { 'is-active': props.isActive },
      { 'thick-underline': props.thickUnderline }
    ]"
  >
    <slot></slot>

    <BaseIcon
      v-if="props.icon"
      :name="props.icon"
      class="ml-1 inline-block text-icon transition-all duration-200 group-hover:ml-2"
      :class="[iconClass]"
    />
  </NuxtLink>
</template>

<style scoped lang="postcss">
.has-underline {
  &::after {
    content: '';
    display: block;
    width: 25%;
    height: 1px;
    background-color: currentColor;
    transition-property: width, opacity;
    transition-duration: 300ms;
    transition-timing-function: var(--ease-smooth);
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
  }

  &.thick-underline::after {
    height: 2px;
  }

  &:hover,
  &.router-link-exact-active,
  &.is-active {
    &::after {
      width: 100%;
      opacity: 1;
    }
  }

  &.router-link-exact-active {
    pointer-events: none;
  }
}
</style>
