<script setup>
import { useBreakpoints, useScrollLock } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { useSchemaOrg, defineOrganization, defineWebSite, defineWebPage } from '@unhead/schema-org/vue';

const layoutStore = useLayout();
const breakpoints = useBreakpoints(customBreakpoints());
const root = useRoot();
const navigation = useNavigation();
const { locale } = useI18n({ useScope: 'global' });
const body = ref();
const { path } = useRoute();

useSchemaOrg([
  defineOrganization({
    name: root.clientName,
    logo: useCdnBase(root.settings.content.siteLogo.url)
  }),
  defineWebSite({
    name: root.clientName
  }),
  defineWebPage()
]);

onMounted(() => {
  const DOM = useDOM();
  body.value = DOM.document.body;
  const isLocked = useScrollLock(body.value);

  watch(
    () => breakpoints,
    () => {
      layoutStore.xs = breakpoints.smaller('sm').value;
      layoutStore.sm = breakpoints.between('sm', 'md').value;
      layoutStore.md = breakpoints.between('md', 'lg').value;
      layoutStore.lg = breakpoints.between('lg', 'xl').value;
      layoutStore.xl = breakpoints.between('xl', 'xxl').value;
      layoutStore.xxl = breakpoints.between('xxl', 'xxxl').value;
      layoutStore.xxxl = breakpoints.greaterOrEqual('xxxl').value;

      layoutStore.calculateDevice();
    },
    { immediate: true, deep: true }
  );

  watch(
    () => root.settings.metadata.culture,
    (newCulture) => {
      locale.value = newCulture;
    }
  );

  watch(
    () => navigation.menuVisible,
    (newIsVisible) => {
      isLocked.value = newIsVisible;
    }
  );

  watch(
    () => layoutStore.scrollLockEnabled,
    (newValue) => {
      isLocked.value = newValue;
    }
  );
});
</script>

<template>
  <NuxtLoadingIndicator color="var(--color-primary)" />
  <NuxtLayout>
    <NuxtRouteAnnouncer />
    <NuxtPage :key="path" />
  </NuxtLayout>
</template>
