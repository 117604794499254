import type { TranslationItem } from 'types';

export async function getTranslations() {
  const loggingService = useLoggingService();

  try {
    return await UseBaseApi<TranslationItem[]>('api/translations/', {
      keepalive: true
    });
  } catch (error) {
    loggingService.error('error setting translations: ', error);
    return [];
  }
}
