import type { SiteSettings } from 'types';

export const useRoot = defineStore('root', () => {
  const { initNavigation } = useNavigation();
  const { useFallbackLocale } = useMultiLingual();

  const clientName = 'Novicell Umbraco Foundation';
  const settings = shallowRef<SiteSettings>({} as SiteSettings);

  async function initSettings(locale = useFallbackLocale()) {
    const { getSettings } = useSettingsApi();

    const { data } = await useAsyncData('settings', () => getSettings(frontendBaseWithLocale(locale)), {
      deep: false
    });

    if (data.value) {
      settings.value = data.value;
    }
  }

  async function reinitializeSettings(locale: string) {
    await Promise.allSettled([initSettings(locale), initNavigation(locale)]);
  }

  return {
    clientName,
    settings,
    initSettings,
    reinitializeSettings
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRoot, import.meta.hot));
}
