export const useLayout = defineStore('layout', () => {
  const scrollLockEnabled = shallowRef<boolean>(false);
  const xs = shallowRef<boolean>(false);
  const sm = shallowRef<boolean>(false);
  const md = shallowRef<boolean>(false);
  const lg = shallowRef<boolean>(false);
  const xl = shallowRef<boolean>(false);
  const xxl = shallowRef<boolean>(false);
  const xxxl = shallowRef<boolean>(false);

  const isMobile = shallowRef<boolean>(false);
  const isXsMobile = shallowRef<boolean>(false);
  const isTablet = shallowRef<boolean>(false);
  const isDesktop = shallowRef<boolean>(false);
  const headerHeight = shallowRef<number>(0);

  const deviceInit = () => {
    const headers = useRequestHeaders();
    const userAgent = headers['user-agent'] as string;

    const testMobile = /Mobile/.test(userAgent);
    isMobile.value = testMobile;
    isXsMobile.value = testMobile;
    isTablet.value = /Tablet/.test(userAgent);
    isDesktop.value = !isMobile.value && !isTablet.value;
  };

  const calculateDevice = () => {
    if (xs.value) {
      isXsMobile.value = true;
    }
    if (sm.value) {
      isXsMobile.value = false;
    }
    if (xs.value || sm.value) {
      isMobile.value = true;
      isTablet.value = false;
      isDesktop.value = false;
      return;
    }

    if (md.value) {
      isMobile.value = false;
      isXsMobile.value = false;
      isTablet.value = true;
      isDesktop.value = false;
      return;
    }

    if (lg.value || xl.value || xxl.value || xxxl.value) {
      isMobile.value = false;
      isXsMobile.value = false;
      isTablet.value = false;
      isDesktop.value = true;
    }
  };

  return {
    scrollLockEnabled,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    xxxl,
    isMobile,
    isXsMobile,
    isTablet,
    isDesktop,
    headerHeight,
    deviceInit,
    calculateDevice
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLayout, import.meta.hot));
}
