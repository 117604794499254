import type { ApplicationInsights } from '@microsoft/applicationinsights-web';

export default function useApplicationInsights(): ApplicationInsights | null {
  const nuxtApp = useNuxtApp();
  const appInsights = nuxtApp?.$appInsights;

  if (!appInsights) {
    return null;
  }

  return appInsights;
}
